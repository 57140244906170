import React from 'react';
import  {graphql} from 'gatsby';
import styled from 'styled-components';;
import BlockContent from '@sanity/block-content-to-react';
import { FaFacebookF, FaTwitter} from 'react-icons/fa';
import {FacebookIcon, TwitterIcon, FacebookShareButton, TwitterShareButton} from "react-share";

import {getGatsbyImageData} from 'gatsby-source-sanity';
import clientConfig from "../../client-config";

import BackgroundImage from 'gatsby-background-image';
import { convertToBgImage } from "gbimage-bridge"
import SEO from '../components/SEO';

export default function BlogPost({data, location}) {

    const BlogPostStyles = styled.div`
        .img-wrapper{
            padding: 0;
        }
        .post-image-header{
            width: 100%;
            background-size: cover;
            min-height: 47vh;
        }
        .overlay{
            background: rgba(47, 47, 22, 0.6);
            width: 100%;
            height: 100%;
            min-height: 47vh;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            p{
                color: white;
                opacity: 0.8;
                max-width: 700px;
                text-align: center;
            }
        
            h1{
                margin-top: 27vh;
                font-weight: bold;
                color: white;
            }

            
        }
        @media (max-width: 770px){
            margin: 0px -20px;
        }
    `;

    const DateSection =styled.div`
        hr{
            color: var(--green);
        }
        h5{
            padding: 0px 20px;
            opacity: 0.5;
            padding-top: 20px;
            padding-bottom: 20px;
        }
    `;

    const ShareSection = styled.div`
        padding: 0px 20px;
        padding-top: 20px;
        h5{
            padding-bottom: 10px;
            font-weight: bold;
        }
        .socials{
            display: flex;
            button{
                box-shadow: 0px 0px 0px #E94799, 0px 0px 0px #E94799;
            }
            .nav-icon{
                font-size: 1.75rem;
                color: var(--pink);
                border-radius: 50%;
                padding: 7px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 15px;
                cursor: pointer;
                background: black;
            }
        }
    `;

    const ContentSection = styled.div`
        padding: 0px 20px;
        padding-bottom: 100px;
    `;

    const gatsbyImageData = getGatsbyImageData(
        data.blog.imagePlusAltHeader.image.asset,
        {maxWidth: 1000},
        clientConfig.sanity
    )
    const gatsbyBGImageData = convertToBgImage(gatsbyImageData);

    return(
        <>
        <SEO 
            title={data.blog.title}
            description={data.blog.previewText}
        />
        <BlogPostStyles>
        <div className="container-fluid">
            <div className="row">
                <div className="col-12 img-wrapper">
                <BackgroundImage 
                    Tag='div'
                    {...gatsbyBGImageData}
                    alt={data.blog.imagePlusAltHeader.alt}
                    className="post-image-header"
                >
                    <div className="overlay">
                        <h1>{data.blog.title}</h1>
                        <p>{data.blog.previewText}</p>
                    </div>
                </BackgroundImage>
                </div>
            </div>
            <div className="row">
                <div className="col-md-2 col-12 offset-md-1">
                    <ShareSection>
                        <h5>Share</h5>
                        <div className="socials">
                            <div className="nav-icon">
                            <FacebookShareButton url={location.href}>
                                <FaFacebookF/>
                            </FacebookShareButton>
                            </div>
                            <div className="nav-icon">
                            <TwitterShareButton url={location.href}>
                                <FaTwitter/>
                            </TwitterShareButton>
                            </div>
                        </div>
                    </ShareSection>
                </div>
                <div className="col-md-8 col-12">
                    <DateSection>
                        <h5>{data.blog._createdAt}</h5>
                        <hr/>
                    </DateSection>
                </div>
            </div>
            <div className="row">
                    <div className="col-md-3 col-12">
                    </div>
                    <div className="col-md-8 col-12">
                        <ContentSection>
                            <BlockContent blocks={data.blog._rawContent}  />
                        </ContentSection>
                    </div>
            </div>
        </div>
        </BlogPostStyles>
        </>

    );
}

//this needs to be dynamic based on the slug passed it
//NOTE: we could have just passed all this data into the context section on
//      gatsby-node.js
export const query = graphql`
    query($slug: String!) {
        blog: sanityFancyblog(slug: {current: {eq: $slug} }) {
            id
            title
            previewText
            _createdAt(formatString: "MMMM DD, YYYY")
            imagePlusAltHeader {
                image {
                    asset {
                        _id
                    }
                }
                alt
            }
            
            _rawContent
            
        }
    }
`;